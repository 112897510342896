import soil from '../utils/soil'
import { DictionaryContent } from '../utils/SoilInstance'
import moment from 'moment'

export async function getDict (
  dictName: string | undefined,
  setter: (value: DictionaryContent) => void,
  appId: string | undefined
): Promise<{} | undefined> {
  if (appId === undefined || dictName === undefined) {
    return {}
  }

  const lang = localStorage.getItem('i18nextLng') ?? 'ca' // TODO: - getLanguage
  const soilInstance = soil.configure(appId)
  const response = await soilInstance.getDictionary(dictName, lang)
  setter(response.content)
}

export const formatStringDate = (value: string): string => {
  return moment(value).format('YYYY-MM-DD HH:mm:00')
}

export const formatMomentDate = (moment: moment.Moment): string => {
  return moment.format('YYYY-MM-DD HH:mm:00')
}
