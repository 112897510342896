import { SoilInstance, DataStructureRef } from './SoilInstance'

export const SOIL_NOT_CONFIGURED_ERROR =
  'Soil has not been configured. Run soil.configure({ host, token })'

let lastSoilInstance: SoilInstance | null = null

/**
 * Creates a new soil instance. Sets the default soil instance
 * to be the default.
 * @example
 * ```ts
 * soil.config(appId: string)
 * ```
 * @param appId - The soil appId
 */
export const configure = (appId: string): SoilInstance => {
  const instance = new SoilInstance(appId)
  lastSoilInstance = instance
  return instance
}

/**
 * Returns a data structure reference given an alias or a result id.
 */
export const data = async (aliasId: string): Promise<DataStructureRef> => {
  if (lastSoilInstance === null) {
    throw new Error(SOIL_NOT_CONFIGURED_ERROR)
  }
  const dataRef = await lastSoilInstance.data(aliasId)
  return dataRef
}

const soil = { configure, data, SoilInstance }

export default soil
