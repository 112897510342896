import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { LOGOUT_URL } from '../../authentification/Logout'

const ErrorLoadingDashboards = (): JSX.Element => (
  <Dialog open fullScreen>
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      <Box sx={{ transform: 'scale(2)' }}>
        <div>Error loading dashboard. Please, contact support.</div>
        <br />
        <Button href={LOGOUT_URL} variant='contained'>
          Logout
        </Button>
      </Box>
    </Box>
  </Dialog>
)

export default ErrorLoadingDashboards
