import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { zip } from 'lodash'

interface DashboardSelectorsProps {
  dashboardIds: string[]
  dashboardNames: Array<string | undefined>
  currentDashboardId: string
  setCurrentDashboardId: React.Dispatch<
  React.SetStateAction<string | undefined>
  >
}

export function DashboardSelectors (
  props: DashboardSelectorsProps
): JSX.Element {
  const {
    dashboardIds,
    setCurrentDashboardId,
    currentDashboardId,
    dashboardNames
  } = props
  const onClick = (e: any): void => {
    setCurrentDashboardId(e.target.value)
    // We reload here because not all components refresh.
    window.location.reload()
  }
  return (
    <Select
      sx={{
        '.MuiSelect-select': (theme) => ({
          color: theme.palette.primary.contrastText
        }),
        '.MuiSelect-icon': (theme) => ({
          color: theme.palette.primary.contrastText
        }),
        '.MuiSelect-nativeInput': (theme) => ({
          borderColor: theme.palette.primary.contrastText
        })
      }}
      value={currentDashboardId}
      onChange={onClick}
    >
      {zip(dashboardIds, dashboardNames).map(([id, name]) => {
        const idStr = id ?? ''
        return (
          <MenuItem key={`item_${idStr}`} value={idStr}>
            {name ?? idStr.replace(/-/g, ' ') ?? ''}
          </MenuItem>
        )
      })}
    </Select>
  )
}
