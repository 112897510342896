import { Fragment } from 'react'
import { useRecoilState } from 'recoil'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import {
  AmalfiDrawer,
  AmalfiList,
  AmalfiListItem,
  AmalfiMain,
  AmalfiAppBar
} from '@amalfi-analytics/components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { pathsItemProps, routeProps } from '../types/types'
import useDictionary from '../utils/useDictionary'
import { LOGOUT_URL } from '../authentification/Logout'
import { drawerOpenAtom } from '../authentification/authState'
import { getAppLogo } from '../utils/appLogo'
import IconButton from '@mui/material/IconButton'
import Icon from '@mdi/react'
import { mdiAccountCog } from '@mdi/js'
import { APPLICATION_ID } from '../authentification/authConfig'

const DEFAULT_NAVIGATION_DICT = 'navigation'

interface OterListItemProps extends pathsItemProps {
  indentation?: number
  searchInDictionary: (
    key?: string | undefined,
    strict?: boolean | undefined
  ) => string
}

export const OuterListItem = (props: OterListItemProps): JSX.Element => {
  const { name, icon, path, indentation, searchInDictionary } = props
  const [location, setLocation] = useLocation()

  return (
    <AmalfiListItem
      selected={path === location}
      text={searchInDictionary(name)}
      path={path}
      icon={icon}
      navComponent={Link}
      indentation={indentation ?? 0}
      onClick={() => setLocation(path)}
    />
  )
}

interface RoutedListProps extends routeProps {
  searchInDictionary: (
    key?: string | undefined,
    strict?: boolean | undefined
  ) => string
}

export function RoutedList (props: RoutedListProps): JSX.Element {
  const { icon, name, searchInDictionary } = props
  const [location] = useLocation()
  return (
    <AmalfiList
      collapsable
      selected={props.paths.map((r) => r.path).includes(location)}
      text={searchInDictionary(name)}
      icon={icon}
    >
      <>
        {props.paths.map((route, i) => (
          <OuterListItem
            key={`${route.path}_${i}`}
            searchInDictionary={searchInDictionary}
            indentation={1}
            {...route}
          />
        ))}
      </>
    </AmalfiList>
  )
}

const UserSettings = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      spacing={0}
    >
      <Grid item>
        <IconButton
          style={{ padding: 0, marginTop: 5, marginBottom: 4 }}
          color='inherit'
          title={t('login.user')}
          target='_blank'
          href={`https://auth.amalfianalytics.com/account/?client_id=${APPLICATION_ID}`}
        >
          <Icon path={mdiAccountCog} size={1} />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant='body2'>{t('login.user')} </Typography>
      </Grid>
    </Grid>
  )
}
interface Props {
  logoName?: string
  children?: JSX.Element
  routesData: routeProps[]
  dashboardSelector?: JSX.Element
  navigationDictionary?: string
  dashboardAppId: string
}

export default function ResponsiveDrawer ({
  logoName,
  routesData,
  dashboardSelector,
  dashboardAppId,
  navigationDictionary,
  children
}: Props): JSX.Element {
  const { t } = useTranslation()
  const { searchInDictionary } = useDictionary(
    navigationDictionary ?? DEFAULT_NAVIGATION_DICT,
    dashboardAppId
  )
  const [open, setOpen] = useRecoilState(drawerOpenAtom)
  const dialogContent = (
    <Box sx={{ pb: 1, pl: 1, pr: 1, maxWidth: 400 }}>
      <Typography>
        {t('help.support')}{' '}
        <Typography
          component='a'
          color='primary'
          href='mailto:suport@amalfianalytics.com'
        >
          suport@amalfianalytics.com
        </Typography>
      </Typography>
    </Box>
  )
  const exitButtonTitle = t('toolbar.exit_button_title')
  const exit = t('toolbar.help')

  const main = (
    //  @ts-expect-error Weird error upgrading to "@types/react-dom": "18.2.17"*
    <AmalfiMain open={open}>
      <Toolbar />
      {children}
    </AmalfiMain>
  )

  const extraElements = (
    <>
      <Grid item>{dashboardSelector}</Grid>
      <Grid item>
        <UserSettings />
      </Grid>
    </>
  )
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AmalfiAppBar
        toolboxProps={{
          logoutProps: {
            href: LOGOUT_URL,
            label: exitButtonTitle
          },
          helpButtonProps: {
            dialogContent,
            label: exit
          },
          extraElements
        }}
        bannerProps={{
          AppLogo: getAppLogo(logoName),
          drawerCallback: () => setOpen(!open)
        }}
      />
      <Box component='nav'>
        <AmalfiDrawer open={open}>
          <AmalfiList>
            <>
              {routesData.map((group, i) => (
                <Fragment key={`group_${i}`}>
                  {group.paths.length > 1
                    ? (
                      <RoutedList
                        searchInDictionary={searchInDictionary}
                        {...group}
                      />
                      )
                    : (
                      <OuterListItem
                        searchInDictionary={searchInDictionary}
                        {...group.paths[0]}
                      />
                      )}
                  <Divider />
                </Fragment>
              ))}
            </>
          </AmalfiList>
        </AmalfiDrawer>
      </Box>
      {main}
    </Box>
  )
}
