import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilValue,
  useSetRecoilState
} from 'recoil'
import Skeleton from '@mui/material/Skeleton'
import { AmalfiCheckboxGroupButtons } from '@amalfi-analytics/components'
import useDictionary from '../../utils/useDictionary'
import { difference, intersection, union } from 'lodash'

export interface paramsProps {
  [property: string]: {
    label: string
  }
}

interface checkboxValueProps {
  value: string | string[]
  selected: boolean
  name: string
}

interface CheckboxAtom {
  options: checkboxValueProps[]
  value: string[]
  name: string
  dictName?: string
  dictAppId?: string
}

interface IrisCheckboxProps {
  atom: RecoilState<CheckboxAtom>
  selector: RecoilValueReadOnly<CheckboxAtom>
}

const DICT_NAME = 'filters'

export default function IrisCheckbox ({
  atom,
  selector
}: IrisCheckboxProps): JSX.Element {
  const setState = useSetRecoilState(atom)
  const state = useRecoilValue(selector)
  const filtersDict = useDictionary(DICT_NAME)
  const { loading, searchInDictionary } = useDictionary(
    state.dictName,
    state.dictAppId
  )

  if (state === undefined || filtersDict.loading || loading) {
    return <Skeleton />
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checkboxValue = JSON.parse(event.target.value)
    if (event.target.checked) {
      setState({ ...state, value: union(state.value, checkboxValue) })
    } else {
      setState({ ...state, value: difference(state.value, checkboxValue) })
    }
  }

  const handleSelectAll = (): void => {
    const value = union(
      ...state.options.map((box) =>
        Array.isArray(box.value) ? box.value : [box.value]
      )
    )
    setState({ ...state, value })
  }
  const handleClean = (): void => {
    setState({ ...state, value: [] })
  }

  const checkbox = {
    error: false,
    labels: {
      label: searchInDictionary(state.name),
      error: ''
    },
    boxesData: state.options.map((box) => {
      const value = Array.isArray(box.value) ? box.value : [box.value]
      console.log(
        'itermediate',
        box.name,
        intersection(value, state.value).length > 0
      )
      return {
        value: JSON.stringify(value),
        checked: difference(value, state.value).length === 0,
        extraProps: {
          indeterminate:
            !(difference(value, state.value).length === 0) &&
            intersection(value, state.value).length > 0
        },
        label: searchInDictionary(box.name),
        onChange: handleChange
      }
    })
  }

  const button = {
    labels: {
      select: filtersDict.searchInDictionary('select'),
      deselect: filtersDict.searchInDictionary('deselect')
    },
    handleSelectAll,
    handleClean
  }

  return (
    <AmalfiCheckboxGroupButtons
      buttonsProps={button}
      checkboxProps={checkbox}
    />
  )
}
