import { isArray, isObject } from 'lodash'
import useDictionary from '../../utils/useDictionary'
import Skeleton from '@mui/material/Skeleton'
import useData from '../../utils/useData'
import { Stackbar } from '@amalfi-analytics/visualizations'
import { IrisStackBarType } from '../../types/componentTypes'
import { StackbarDataProps } from '@amalfi-analytics/visualizations/dist/types/components/Stackbar'
import { useTranslation } from 'react-i18next'

const IrisStackBar = ({
  element
}: {
  element: IrisStackBarType
}): JSX.Element => {
  const data = useData(element)
  const { searchInDictionary, loading, error, dictionary } = useDictionary(
    element.dictName,
    element.applicationID
  )
  const { t } = useTranslation()
  const div = element.div ?? { height: 100 }
  if (error !== undefined || loading) {
    return (
      <div style={div}>
        <Skeleton
          variant='rectangular'
          width='96%'
          height='100%'
          style={{ margin: 10 }}
        />
      </div>
    )
  } else if (data.data === undefined) {
    return <h1 color='error'>{t('error.no_data')}</h1>
  }
  let dict: { [key: string]: string } | undefined
  if (dictionary !== null) {
    if (isObject(dictionary.content.list)) {
      dict = dictionary.content.list
    } else {
      dict = dictionary.content as any as { [key: string]: string }
    }
  }
  let translatedData = data.data as StackbarDataProps
  if (isArray(translatedData)) {
    translatedData = translatedData.map((serieProp) => ({
      ...serieProp,
      data: serieProp.data.map((serieData) => ({
        ...serieData,
        x: searchInDictionary(serieData.x)
      }))
    }))
  } else {
    translatedData = {
      ...translatedData,
      data: translatedData.data.map((serieData) => ({
        ...serieData,
        x: searchInDictionary(serieData.x)
      }))
    }
  }
  return (
    <div style={div}>
      <Stackbar
        data={translatedData}
        {...element.props}
        dictionary={dict}
        renderDependencies={[data.data]}
      />
    </div>
  )
}

export default IrisStackBar
