import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useLocalStorage, useInterval, useAsync } from 'react-use'
import soil from './soil'
import { DataStructureRef } from './SoilInstance'
import { queriesFamily } from '../filters/filtersStateIris'
import { DASHBOARD_UPDATE_TIME } from '../authentification/authConfig'
import { buildQuery, ContainedItem } from '../dashboard/dashboardUtils'

interface Query {
  fn?: string | undefined
  query?: object | undefined
  source?: string | undefined
  aggregation?: object | undefined
  fn_args?: object | undefined
  lang?: string | undefined
}

async function getDataWithRetries (
  dataRef: DataStructureRef,
  query: Query,
  maxRetires: number = 3
): Promise<unknown> {
  let retry = 1
  while (retry <= maxRetires) {
    try {
      const result = await dataRef.getData(query)
      return result
    } catch (error) {
      console.error(error)
      retry += 1
    }
  }
  throw new Error(`Max retries exceeded for query: ${JSON.stringify(query)}`)
}

function useData<T = any> (
  element: ContainedItem
): {
    data?: T
    loading: boolean
    error?: Error
  } {
  const filters = element?.filters ?? []
  const [count, setCount] = useState(0)
  const [data, setData] = useState<T>()
  const query = useRecoilValue(queriesFamily(filters))
  const [language] = useLocalStorage('i18nextLng', 'ca', {
    raw: false,
    serializer: String,
    deserializer: String
  })

  async function getResult (): Promise<T> {
    const returnDefault = (): T => {
      if (element?.props?.data !== undefined) {
        return element?.props?.data
      }
      throw new Error('Missing applicationId or alias')
    }
    if (element.applicationID === undefined || element.alias === undefined) {
      return returnDefault()
    }
    const soilInstance = soil.configure(element.applicationID)
    const dataRef = await soilInstance.data(element.alias)
    const question = buildQuery(element, query as object, language ?? 'ca')
    if (question === null) {
      throw new Error(`Invalid query ${JSON.stringify(query)}`)
    }
    const result = (await getDataWithRetries(dataRef, question)) as T
    return result
  }
  // Update data periodically.
  useInterval(() => {
    setCount(count + 1)
  }, 1000 * DASHBOARD_UPDATE_TIME)

  const { value, loading, error } = useAsync(getResult, [
    JSON.stringify(query),
    count
  ])

  // This use effect with setData avoids screen flickering
  // when loading for second time.
  useEffect(() => {
    if (!loading) {
      setData(value)
    }
  }, [loading, value])

  return {
    data,
    loading: loading || (data === undefined && error === undefined),
    error
  }
}

export default useData
