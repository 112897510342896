import Dialog from '@mui/material/Dialog'
import { APPLICATION_ID, LOGIN_PATH, LOGIN_URL } from './authConfig'
import Box from '@mui/material/Box'

const redirectUri = encodeURIComponent(
  // eslint-disable-next-line no-restricted-globals
  `${location.protocol}//${location.host}/`
)
const LOGIN_URI = `${LOGIN_URL}${LOGIN_PATH}?client_id=${APPLICATION_ID}&redirect_uri=${redirectUri}&state=${Math.random()
  .toString()
  .slice(2)}`

export default function IrisLogin (): JSX.Element {
  window.location.href = LOGIN_URI
  return (
    <Dialog open fullScreen>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Box sx={{ transform: 'scale(2)' }}>
          <div style={{ maxWidth: 500 }}>
            Automatically redirecting to <br />
            <a href={LOGIN_URI}>{LOGIN_URI}</a>
          </div>
        </Box>
      </Box>
    </Dialog>
  )
}
