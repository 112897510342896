import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Locize from 'i18next-locize-backend'
import resources from './translations'

const FALLBACK_LANG = 'ca'

void i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Locize)
  .init({
    fallbackLng: FALLBACK_LANG,
    defaultNS: 'default',
    resources,
    debug: false,
    interpolation: {
      // react already safes from xss
      escapeValue: false
    },
    detection: {
      lookupCookie: 'app.locale',
      lookupQuerystring: 'locale',
      // order: ['localStorage'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    }
  })
