import { AxiosInstance } from 'axios'
import { http } from '../authentification/authState'

export const SOIL_STATE_PATH = 'states'
export const SOIL_RESULT_PATH = 'results'

export const SOIL_NOT_CONFIGURED_ERROR =
  'Soil has not been configured. Run soil.configure({ host, token })'
export const SOIL_USER_NOT_AUTHORIZED_ERROR = 'Unauthorized user.'
export const SOIL_RESULT_NOT_FOUND = 'Result not found.'
export const SOIL_RETRIEVE_RESULT_ERROR = 'Unexpected error retrieving result.'

interface Error {
  status?: number
}

export class SoilInstance {
  axiosInstance: Promise<AxiosInstance>
  constructor (appId: string) {
    this.axiosInstance = http(appId)
  }

  public async data (aliasId: string): Promise<DataStructureRef> {
    const aliasRequest = await (
      await this.axiosInstance
    ).get(`${SOIL_STATE_PATH}/?name=${aliasId}&show-states&with-roles`)

    let resultId = aliasId
    let alias: string | null = null
    if (aliasRequest.data.length > 0) {
      const state = aliasRequest.data[0].state
      alias = state.alias
      resultId = state.result_id
    }
    try {
      const resultRequest = await (
        await this.axiosInstance
      ).get(`${SOIL_RESULT_PATH}/${resultId}/`)

      if (resultRequest.status === 200) {
        return new DataStructureRef(
          this,
          resultId,
          alias,
          resultRequest.data.metadata
        )
      }
    } catch (err: unknown) {
      const error = err as Error
      if (error.status === 401) {
        throw new Error(SOIL_USER_NOT_AUTHORIZED_ERROR)
      }
      if (error.status === 404) {
        throw new Error(SOIL_RESULT_NOT_FOUND)
      }
    }

    throw new Error(SOIL_RETRIEVE_RESULT_ERROR)
  }

  /**
   * Calls the dictionary server and retrieve them.
   * If no language found, returns de default language.
   * the params.
   * @example
   * ```ts
   * soilAlias.getDictionary({name: string, language: string})
   * ```
   */
  public async getDictionary (
    name: string,
    language: string
  ): Promise<Dictionary> {
    const url = `dictionaries/${name}/?language=${language}`
    const doc = await (await this.axiosInstance).get<Dictionary>(url)
    return doc.data
  }
}

export interface DictionaryContent {
  list: { [key: string]: string }
}

export interface Dictionary {
  name: string
  language: string
  content: DictionaryContent
}

export class DataStructureRef {
  resultId: string
  alias: string | null
  soilInstance: SoilInstance
  metadata: any
  constructor (
    soilInstance: SoilInstance,
    resultId: string,
    alias: string | null,
    metadata: any
  ) {
    this.soilInstance = soilInstance
    this.resultId = resultId
    this.alias = alias
    this.metadata = metadata
  }

  /**
   * Calls the get_data method of the data structure passing
   * the params. Params must be json serializable.
   * @example
   * ```ts
   * dataRef.getData({test: 1, param: 2})
   * ```
   * Will run in the python data structure:
   * ```python
   * data_ref.get_data(test=1, param=2)
   * ```
   * @param params The query params
   */
  public async getData<T>(params: { [key: string]: any } = {}): Promise<T> {
    const queryParams = Object.entries(params)
      .map(([k, v]) => `${k}=${JSON.stringify(v)}`)
      .join('&')
    const url = `results/${this.resultId}/data/?${queryParams}`
    const result = await (await this.soilInstance.axiosInstance).get(url)
    return result.data.results
  }

  /**
   * Calls the get_data method of the data structure passing
   * the params. Params must be json serializable.
   * @example
   * ```ts
   * dataRef.export({format: 1, params: {}})
   * ```
   * Will run in the python data structure:
   * ```python
   * data_ref.export(format=1, params={})
   * ```
   * @param params The query params
   */
  public async export<T>(
    format: string,
    params: { [key: string]: any } = {}
  ): Promise<T> {
    const queryParams = Object.entries(params)
      .map(([k, v]) => `${k}=${JSON.stringify(v)}`)
      .join('&')
    const url = `results/${this.resultId}/export/?format=${format}&${queryParams}`
    const result = await (
      await this.soilInstance.axiosInstance
    ).get(url, {
      responseType: 'blob'
    })
    return result.data
  }
}
