import Cookies from 'universal-cookie'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { atom } from 'recoil'
import { configurationType } from '../types/types'
import {
  SOIL_URL,
  FILTER_ROLE,
  API_PATH,
  LOGIN_URL,
  APPLICATION_ID,
  REFRESH_PATH,
  LOGOUT_PATH
} from './authConfig'
import { memoize } from 'lodash'
import axios from 'axios'
import type { AxiosInstance, AxiosError } from 'axios'

const RECENT_SESSION = 10 * 60 * 1000
const REFRESH_TIME = 15 * 60 * 1000
let autoRefreshInterval: number | null = null

export function hasValidSession (): boolean {
  const cookie = new Cookies()
  const exp = cookie.get('app.at_exp')
  return exp !== undefined
    ? new Date(exp * 1000 - 60 * 1000) > new Date()
    : false
}

interface UserApplication {
  id: string
  name: string
  roles: string[]
  soil: string
}

const getUserApplications = memoize(async (): Promise<UserApplication[]> => {
  const response = await axios.get(`${SOIL_URL}/applications/`, {
    withCredentials: true
  })
  return response.data.userApplications
})

export async function getAppIds (): Promise<string[]> {
  const userApplications = await getUserApplications()
  const apps: Array<{ id: string }> = userApplications.filter((app) =>
    app.roles.includes(FILTER_ROLE)
  )
  return apps.map((app) => app.id)
}

export const getSoil = memoize(async (appId: string): Promise<string> => {
  const app = (await getUserApplications()).find((app) => app.id === appId)
  if (app === undefined) {
    throw new Error(`Application ${appId} not found`)
  }
  return app.soil
})

function recentSession (): boolean {
  // the session has been refreshed recently
  const cookie = new Cookies()
  const exp = cookie.get('app.at_exp') ?? new Date().getTime() / 1000
  return new Date().getTime() - exp * 1000 < RECENT_SESSION
}

const localStorageEffect =
  (key: string) =>
    ({ setSelf, onSet }: any) => {
      let storage =
      localStorage.getItem('remember') === 'true'
        ? localStorage
        : sessionStorage

      const savedValue = storage.getItem(key)
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue))
      }

      onSet((newValue: any) => {
        storage =
        localStorage.getItem('remember') === 'true'
          ? localStorage
          : sessionStorage
        if (newValue === null) {
          storage.removeItem(key)
        } else {
          storage.setItem(key, JSON.stringify(newValue))
        }
      })
    }

export type RefreshToken = string
export interface refreshTokensAtomType {
  [appId: string]: RefreshToken
}
export const refreshTokensAtom = atom<null | refreshTokensAtomType>({
  key: 'RefreshTokens',
  default: null,
  effects_UNSTABLE: [localStorageEffect('refresh_tokens')]
})

export const currentDashboardAtom = atom<undefined | string>({
  key: 'CurrentDashboard',
  default: undefined,
  effects_UNSTABLE: [localStorageEffect('current_dashboard')]
})

export const drawerOpenAtom = atom<boolean>({
  key: 'DrawerOpen',
  default: true,
  effects_UNSTABLE: [localStorageEffect('drawer_open')]
})

export type Dashboard = string
export const dashboardsAtom = atom<null | configurationType[]>({
  key: 'Dashboards',
  default: null
})

const clearStorage = (): void => {
  const storage = window.localStorage
  if (storage !== undefined) {
    storage.clear()
  }
}

async function autoRefreshLogic (): Promise<void> {
  try {
    await axios.post(
      `${LOGIN_URL}${REFRESH_PATH}?client_id=${APPLICATION_ID}`,
      {},
      { withCredentials: true }
    )
    console.info('Refreshed token succesfully.')
  } catch (error) {
    console.info(
      'Failed refresh token with code',
      (error as AxiosError).response?.status,
      'logging out.'
    )
    if (autoRefreshInterval !== null) {
      clearInterval(autoRefreshInterval)
      autoRefreshInterval = null
    }
    clearStorage()
    // eslint-disable-next-line no-restricted-globals
    location.href = `${LOGIN_URL}${LOGOUT_PATH}?client_id=${APPLICATION_ID}&post_logout_redirect_uri=${encodeURIComponent(
      `${location.protocol}//${location.host}/` // eslint-disable-line no-restricted-globals
    )}`
  }
}

const refreshAuthLogic = async (
  failedRequest: AxiosError<string>
): Promise<void> => {
  if (!recentSession()) {
    // avoid loops, hopefully
    await autoRefreshLogic()
    return
  }
  await Promise.reject(failedRequest)
}

async function createHttpInstance (
  applicationId: string
): Promise<AxiosInstance> {
  if (applicationId === undefined) {
    throw new Error('Application id is undefined')
  }
  const headers: { ['Application-Id']?: string } = {
    'Application-Id': applicationId
  }
  const soilUrl = await getSoil(applicationId)
  const axiosInstance = axios.create({
    baseURL: `${soilUrl}${API_PATH}`,
    headers,
    withCredentials: true
  })
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
    skipWhileRefreshing: false
  })
  return axiosInstance
}

export const http = memoize(async (appId: string): Promise<AxiosInstance> => {
  if (autoRefreshInterval === null) {
    if (!recentSession()) {
      autoRefreshLogic()
        .then(() => {})
        .catch(() => {})
    }
    autoRefreshInterval = setInterval(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      autoRefreshLogic,
      REFRESH_TIME
    ) as unknown as number
  }
  return await createHttpInstance(appId)
})
