/* eslint-disable @typescript-eslint/naming-convention */

// @ts-expect-error
const config = window.CONFIG

// Time in seconds to update the dashboard. Default 1 minute.
export const DASHBOARD_UPDATE_TIME: number = config.dashboard_update_time ?? 60
export const SOIL_URL: string =
  config.soilUrl ?? 'https://soil.amalfianalytics.com'
export const APP_NAME = config.appName as string | undefined
export const API_PATH: string = config.apiPath ?? '/api/v2'
export const LOGIN_URL: string =
  config.loginUrl ?? 'https://auth.amalfianalytics.com'
export const LOGIN_PATH: string = config.loginPath ?? '/app/login'
export const APPLICATION_ID: string = config.applicationId
export const FILTER_ROLE: string = config.filterRole ?? 'iris'
export const DASHBOARDS_URL: string =
  config.dashboardsUrl ?? `${SOIL_URL}/services/dashboards`
export const REFRESH_PATH: string = config.refreshPath ?? '/app/refresh'
export const LOGOUT_PATH: string = config.refreshPath ?? '/app/logout'
