import { useMount } from 'react-use'
import { flatten, keys } from 'lodash'
import { configurationProps, filterProps, filterObject } from '../types/types'
import { useRecoilState } from 'recoil'
import { Route, Switch } from 'wouter'
import ResponsiveDrawer from '../drawer/ResponsiveDrawer'
import { AmalfiFiltersMenu } from '../filters/AmalfiFiltersMenu'
import { filtersDefault } from '../filters/filtersStateIris'
import Dashboard from './Dashboard'

export interface DisplayDashboardProps {
  dashboard: configurationProps
  dashboardSelector?: JSX.Element
}

const DisplayDashboard = ({
  dashboard,
  dashboardSelector
}: DisplayDashboardProps): JSX.Element => {
  const [filtersDefaultValue, setFiltersDefault] =
    useRecoilState(filtersDefault)
  useMount(() => {
    if (keys(filtersDefaultValue).length > 0) {
      return
    }
    const filtersDefaultsRepository: { [filterId: string]: filterObject } = {}
    dashboard.filters.forEach((w: filterProps) => {
      filtersDefaultsRepository[w.key] = w.default
    })
    setFiltersDefault(filtersDefaultsRepository)
  })
  if (keys(filtersDefaultValue).length === 0) {
    return <></>
  }

  const routesData = dashboard.dashboard
  const routes = routesData.map((group) =>
    group.paths.map((route) => {
      if ((route.configuration.filters?.length ?? 0) > 0) {
        return (
          <Route path={route.path} key={route.path}>
            <AmalfiFiltersMenu config={route.configuration?.filters ?? []} />
            <Dashboard components={route.configuration.components} />
          </Route>
        )
      }
      return (
        <Route path={route.path} key={route.path}>
          <Dashboard components={route.configuration.components} />
        </Route>
      )
    })
  )

  return (
    <ResponsiveDrawer
      logoName={dashboard.logo}
      routesData={routesData}
      dashboardSelector={dashboardSelector}
      navigationDictionary={dashboard.navigationDictionary}
      dashboardAppId={dashboard.appsId[0]}
    >
      <Switch>{flatten(routes)}</Switch>
    </ResponsiveDrawer>
  )
}

export default DisplayDashboard
