// Import utils
import axios from 'axios'
import { difference } from 'lodash'

// Import host elements
import { getApplication } from '../utils/host'

// Import auth elements
import { DASHBOARDS_URL } from './authConfig'
import { getAppIds, getSoil } from './authState'

// Import types
import { configurationType } from '../types/types'
import { SetterOrUpdater } from 'recoil'

/**
 * Gets all available dashboards from all the apps the user
 * has access.
 * @returns
 */
async function getDashboards (): Promise<configurationType[]> {
  const appIds = await getAppIds()
  let dashboards: configurationType[] = []
  for (const appId of appIds) {
    // dashboards url can be fixed for development or use the soil from the app
    let dashboardsUrl = DASHBOARDS_URL
    if (!dashboardsUrl.startsWith('http')) {
      dashboardsUrl = (await getSoil(appId)) + DASHBOARDS_URL
    }
    const response = await axios.get<{ result: configurationType[] }>(
      DASHBOARDS_URL,
      {
        headers: {
          'Content-Type': 'application/json',
          'Application-Id': appId
        },
        withCredentials: true
      }
    )
    if (response.status !== 200 || response.data.result.length === 0) {
      console.warn(`Dashboards not found for appId ${appId}`)
      continue
    }
    dashboards = dashboards.concat(response.data.result)
  }
  return dashboards
}

/**
 * Filters out the dashoards that do not match the app name.
 * If there is no app name it does not filter out any dashboard.
 */
function filterDashboardByApp (
  dashboards: configurationType[]
): configurationType[] {
  const location = getApplication()
  return dashboards.filter(
    (d) => location === undefined || d.role.includes(location)
  )
}

function extractAppIds (dashboard: configurationType): string[] {
  const appsIds: string[] = []
  dashboard.dashboard_configs.forEach((dashboardConfig) =>
    dashboardConfig.appsId.forEach((appId) => {
      if (!appsIds.includes(appId)) {
        appsIds.push(appId)
      }
    })
  )
  return appsIds
}

async function filterDashboardsWithoutAccess (
  dashboards: configurationType[]
): Promise<configurationType[]> {
  const tokenAppIds = await getAppIds()
  return dashboards.filter(
    (dashboard) =>
      difference(extractAppIds(dashboard), tokenAppIds).length === 0
  )
}

export async function refreshDashboards (
  setDashboardAtom: SetterOrUpdater<configurationType[] | null>
): Promise<configurationType[]> {
  let dashboards = await getDashboards()
  dashboards = filterDashboardByApp(dashboards)
  dashboards = await filterDashboardsWithoutAccess(dashboards)
  setDashboardAtom(dashboards)
  return dashboards
}
